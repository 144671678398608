body {
  margin: 0;
  width: 100%;
  height: 100%;
	background: #F5F6F7;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  color: #2A2A2A;
  font-family:-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
article,
aside,
footer,
header,
nav,
section {
display: block;
}

h1 {
font-size: 2em;
margin: 0.67em 0;
}

figcaption,
figure,
main { /* 1 */
display: block;
}

figure {
margin: 1em 40px;
}

hr {
box-sizing: content-box; /* 1 */
height: 0; /* 1 */
overflow: visible; /* 2 */
}

pre {
font-family: monospace, monospace; /* 1 */
font-size: 1em; /* 2 */
}

a {
background-color: transparent; /* 1 */
-webkit-text-decoration-skip: objects; /* 2 */
}


abbr[title] {
border-bottom: none; /* 1 */
text-decoration: underline; /* 2 */
text-decoration: underline dotted; /* 2 */
}

b,
strong {
font-weight: inherit;
}

b,
strong {
font-weight: bolder;
}

code,
kbd,
samp {
font-family: monospace, monospace; /* 1 */
font-size: 1em; /* 2 */
}

dfn {
font-style: italic;
}

mark {
background-color: #ff0;
color: #000;
}


small {
font-size: 80%;
}

sub,
sup {
font-size: 75%;
line-height: 0;
position: relative;
vertical-align: baseline;
}

sub {
bottom: -0.25em;
}

sup {
top: -0.5em;
}


audio,
video {
display: inline-block;
}
audio:not([controls]) {
display: none;
height: 0;
}

img {
border-style: none;
}

svg:not(:root) {
overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
font-family: sans-serif; /* 1 */
font-size: 100%; /* 1 */
line-height: 1.15; /* 1 */
margin: 0; /* 2 */
}
button,
input { /* 1 */
overflow: visible;
}
button,
select { /* 1 */
text-transform: none;
}

button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
-webkit-appearance: button; /* 2 */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
border-style: none;
padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
outline: 1px dotted ButtonText;
}

fieldset {
padding: 0.35em 0.75em 0.625em;
}

legend {
box-sizing: border-box; /* 1 */
color: inherit; /* 2 */
display: table; /* 1 */
max-width: 100%; /* 1 */
padding: 0; /* 3 */
white-space: normal; /* 1 */
}


progress {
display: inline-block; /* 1 */
vertical-align: baseline; /* 2 */
}

textarea {
overflow: auto;
}

[type="checkbox"],
[type="radio"] {
box-sizing: border-box; /* 1 */
padding: 0; /* 2 */
}


[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
height: auto;
}

[type="search"] {
-webkit-appearance: textfield; /* 1 */
outline-offset: -2px; /* 2 */
}


[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
-webkit-appearance: none;
}


::-webkit-file-upload-button {
-webkit-appearance: button; /* 1 */
font: inherit; /* 2 */
}

details, /* 1 */
menu {
display: block;
}


summary {
display: list-item;
}

canvas {
display: inline-block;
}

template {
display: none;
}

[hidden] {
display: none;
}


/* reset */
*{
box-sizing: border-box;
}
html, body{/* 禁止选中文本 */
/* -webkit-user-select: none;
user-select: none; */
font: Oswald, 'Open Sans', Helvetica, Arial, sans-serif
}
/* 禁止长按链接与图片弹出菜单 */
a, img {
-webkit-touch-callout: none; 
}

/*ios android去除自带阴影的样式*/
a, input{ 
-webkit-tap-highlight-color:rgba(0,0,0,0);
}

input[type="text"] {
-webkit-appearance: none;
}


html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,blockquote,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu{margin:0;padding:0;}
header,footer,section,article,aside,nav,hgroup,address,figure,figcaption,menu,details{display:block;}
table{border-collapse:collapse;border-spacing:0;}
caption,th{text-align:left;font-weight:normal;}
html,body,fieldset,img,iframe,abbr{border:0; color: rgba(42, 42, 42, 1) ; overflow-y:scroll ;}
i,cite,em,var,address,dfn{font-style:normal;}
[hidefocus],summary{outline:0;}
li{list-style:none;}
h1,h2,h3,h4,h5,h6,small{font-size:100%;}
sup,sub{font-size:83%;}
pre,code,kbd,samp{font-family:inherit;}
q:before,q:after{content:none;}
textarea{overflow:auto;resize:none;}
label,summary{cursor:default;}
a,button{cursor:pointer;}
h1,h2,h3,h4,h5,h6,em,strong,b{font-weight:bold;}
del,ins,u,s,a,a:hover{text-decoration:none;}
  
  