.Tabs_box{
    height: 65px;
    overflow: hidden;
    position: relative;
    transition: all .2s;
    button{
        border:none;
        outline: none;
        background: #fff;
        padding:10px 0px;
        margin-right: 40px;        
        font-size: 16px;
        // font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #2A2A2A;
        cursor: pointer;
        transition: all .2s;
        &:hover{
            color:var(--themeColor)!important;
        }
    }
    i{
        width: 20px;
        height: 3px;
        background: var(--themeColor);
        border-radius: 2px;
        display: block;
    }
}