body{
  --themeColor: var(--themeColor);
  --fizeColor: var(--fizeColor);
  --headerBg: var(--headerBg);
  --themeRgba: var(--themeRgba);
}
/* 禁止选中文本 */
.usn{
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	-o-user-select:none;
	user-select:none;
}
/* 浮动 */
.fl {
	float: left;
}
.fr {
	float: right;
}
.cf {
	zoom: 1;
}
.cf:after {
	content:".";
	display:block;
	clear:both;
	visibility:hidden;
	height:0;
	overflow:hidden;
}

/* 元素类型 */
.db {
	display: block;
}
.dn {
	display: none;
}
.di {
	display:inline-block;
	*display:inline;
	*zoom:1;
}

/* 溢出样式 */
.ofh {
	overflow: hidden;
}
.ofs {
	overflow: scroll;
}
.ofa {
	overflow: auto;
}
.ofv {
	overflow: visible;
}

/* 定位方式 */
.ps {
	position: static;
}
.pr {
	position: relative;
	zoom:1;
}
.pb {
	position: absolute;
}
.pf {
	position: fixed;
}


/* 文本对其方式 */
.tc {
	text-align: center;
}
.tl {
	text-align: left;
}
.tj {
	text-align: justify;
	text-justify: inter-ideograph;
}
/* 垂直对齐方式 */
.vt {
	vertical-align: top;
}
.vm {
	vertical-align: middle;
}
.vb {
	vertical-align: bottom;
}


.wn { /* 强制不换行 */
	word-wrap:normal;
	white-space:nowrap;
}
.wb { /* 强制换行 */
	white-space:normal;word-wrap:break-word;word-break:break-all;
}
.wp { /* 保持空白序列*/
	overflow:hidden;text-align:left;white-space:pre-wrap;word-wrap:break-word;word-break:break-all;
}


.tdu, .tdu:hover { /* 下划线 */
	text-decoration:underline;
}

.tdn, .tdn:hover{ /* 去掉下划线样式 */
	text-decoration:none;
}

.wes { /* 多出部分用省略号表示 , 用于一行 */
	overflow:hidden;
	word-wrap:normal;
	white-space:nowrap;
	text-overflow:ellipsis;
}
 .wes-2 { /* 适用于webkit内核和移动端 */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	word-break: break-all;
	word-wrap: break-word;
	white-space: pre-wrap;
}
.wes-3 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	word-break: break-all;
	word-wrap: break-word;
	white-space: pre-wrap;
}
.wes-4 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
	word-break: break-all;
	word-wrap: break-word;
	white-space:pre-wrap;
}

/* 鼠标样式 */
.csd {
	cursor: default;
}
.csp {
	cursor: pointer;
}
.csh {
	cursor: help;
}
.csm {
	cursor: move;
}


.dfc {
    display: flex;
    align-items: center;
    justify-content: center;
}

.f12 {
	font-size: 12px;
}

.f14 {
	font-size: 14px;
}

.f15 {
	font-size: 15px;
}

.f16 {
	font-size: 16px;
}

.f23 {
	font-size: 23px;
}

.hide {
	display: none;
}

.show-inline {
	display: inline-block;
}

.show {
	display: block;
}

.icon_symbol {
	width: 25px;
	height: 25px;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}


/* 修改部分antd样式 */

.ant-form-explain{
	padding-left: 15px;
}
.ant-form-item-children{
	position: initial !important;
}
.teacher_form_out .ant-form-item-children{
	position: relative !important;
}
.ant-form-item-control.has-feedback{
	position: relative !important;
}

/*控制整个滚动条*/
/* ::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  height: 6px;
} */

::-webkit-scrollbar{
  width:6px;
  height:6px;
  background-color:#fff;
  }
::-webkit-scrollbar:hover{
  background-color:#eee;
}
::-webkit-scrollbar-thumb{
  min-height: 5px;
  min-width: 5px;
  border-radius: 20px;
  /* border:1px solid#888; */
  background-color: #AAA;
}
::-webkit-scrollbar-thumb:hover{
  border-color: #444;
}
::-webkit-scrollbar-thumb:active{
  border-color: #444;
} 