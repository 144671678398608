/*
 *首页科目 搜索hover换色
 */
.iconsousuo:hover {
  color: var(--themeColor) !important;
  cursor: pointer !important;
}
/*
 *首页科目
 *字、边框不带色，字、边框hover换色
 */
.change-font-border-hover:hover {
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
  font-weight: 400px !important;
  cursor: pointer !important;
}
.change-font-border-hover .iconfont {
  color: var(--themeColor) !important;
}
/*
 *做题设置 题型
 *字、边框不带色
 */
.change-font-border-actived-before {
  color: #666666 !important;
  border: 1px solid #DADEE0 !important;
}
/*
 *做题设置 题型
 *字、边框不带色，hover/actived字、边框变色
 */
.change-font-border-actived {
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
  font-weight: 400px !important;
  cursor: pointer !important;
}
/*
 *首页展开收起科目列表
 *详情页 下拉框里科目
 *字hover换色
 */
.change-font-hover:hover {
  color: var(--themeColor) !important;
  cursor: pointer !important;
}
.change-font-hover .iconfont:hover {
  color: var(--themeColor) !important;
  cursor: pointer !important;
}
/*
 *tab切换 详情页、做题页、做题记录、
 *字hover、点击换色
 */
.detail-box .ant-tabs-tab:hover,
.material .ant-tabs-tab:hover,
.record-wrapper .ant-tabs-tab:hover {
  color: var(--themeColor) !important;
}
.detail-box .ant-tabs-tab-active,
.material .ant-tabs-tab-active,
.record-wrapper .ant-tabs-tab-active {
  color: var(--themeColor) !important;
}
.detail-box .ant-tabs-ink-bar,
.material .ant-tabs-ink-bar,
.record-wrapper .ant-tabs-ink-bar,
.record-wrapper .ant-tabs-tab-active::after,
.detail-box .ant-tabs-tab-active::after {
  background: var(--themeColor) !important;
}
/*
 *数据中心
 *字带色
 */
.change-font-color {
  color: var(--themeColor) !important;
}
/*
 *数据中心 切换 按钮
 *字带色，hover背景变深
 */
.change-background-hover-deep {
  color: var(--themeColor) !important;
}
.change-background-hover-deep:hover {
  color: var(--themeColor) !important;
  background: rgba(var(--themeRgba), 0.08) !important;
  cursor: pointer !important;
}
/*
 *数据中心 切换下列表
 *详情页 下拉框里科目
 *字hover换色
 */
.switch.ant-dropdown-trigger {
  color: var(--themeColor) !important;
}
.switch.ant-dropdown-trigger:hover {
  background: rgba(var(--themeRgba), 0.08) !important;
}
.switch.ant-dropdown-trigger.ant-dropdown-open {
  background: rgba(var(--themeRgba), 0.08) !important;
}
.static-center-dropdown li:hover {
  background: rgba(var(--themeRgba), 0.08) !important;
  color: var(--themeColor) !important;
}
/*
 *详情页显示更多
 *字、背景带色，hover背景变深,
 */
.change-font-background-hover-deep {
  color: var(--themeColor) !important;
  background: rgba(var(--themeRgba), 0.08) !important;
}
.change-font-background-hover-deep:hover {
  color: var(--themeColor) !important;
  background: rgba(var(--themeRgba), 0.15) !important;
  cursor: pointer !important;
}
/*
 *详情页 顺序刷题、开始做题、继续做题
 *字、边框带色，hover字变白，背景变色,点击背景变浅
 */
.change-font-background-hover {
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
}
.change-font-background-hover:hover {
  color: #FFFFFF !important;
  background: var(--themeColor) !important;
  border: none;
  cursor: pointer !important;
}
.change-font-background-hover:active {
  opacity: 0.8 !important;
}
/*
 *详情页 快速做题、智能组卷
 *字白色、背景带色，hover背景变浅
 */
.change-font-white-background-shallow {
  border: none;
  color: #FFFFFF !important;
  background: var(--themeColor) !important;
}
.change-font-white-background-shallow:hover {
  opacity: 0.8;
  cursor: pointer !important;
}
/*
 *题型设计 针对练习、题目数量
 *字、背景不带色，hover变色
 */
.change-font-white-background {
  border: none;
  color: #FFFFFF !important;
  background: var(--themeColor) !important;
}
.change-font-white-background-hover:focus,
.change-font-white-background-hover:hover {
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
  cursor: pointer !important;
}
/*
	*每日一练、做题页
	*div前的竖线
	*/
.change-before-color:before {
  background: var(--themeColor) !important;
}
/*
	*做题页
	*div后的竖线
	*/
.change-after-color:after {
  background: var(--themeColor) !important;
}
/*
	*做题页
	*div前的竖线
	*/
.change-background-color {
  background: var(--themeColor) !important;
}
/*
	*做题页
	*材料题tab
	*/
.subject-wrap-doing .content-box .content-left .cont-left-body .question-options .material .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active:after {
  background: var(--themeColor) !important;
}
/*
	*考试页面弹框
	*/
.submit-modal .ant-btn:hover {
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
  font-weight: 400px !important;
  cursor: pointer !important;
}
.submit-modal .ant-btn-primary {
  border: none;
  color: #FFFFFF !important;
  background: var(--themeColor) !important;
}
.submit-modal .ant-btn-primary:hover {
  opacity: 0.8;
  color: #FFFFFF !important;
  cursor: pointer !important;
}
/*
	*分页
  */
.ant-pagination .ant-pagination-item:hover {
  border-color: var(--themeColor) !important;
}
.ant-pagination .ant-pagination-item a:hover {
  color: var(--themeColor) !important;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  border-color: var(--themeColor) !important;
  background: var(--themeColor) !important;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active:hover a {
  color: #fff !important;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active a {
  color: #fff !important;
}
.ant-pagination .ant-pagination-item .ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link {
  color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}
/*章节练习展开节时标题对应的图标变颜色*/
.Chapter-exercise .ant-collapse-item-active .chapter-title::before {
  border: 4px solid var(--themeColor) !important;
}
/* 真题模拟 - 查看报告 */
.simulation .simulation-paper .paper-list .paper-progress .progress .progress-status.finished {
  color: var(--themeColor) !important;
}
.report-btn .change-font-border-actived:hover {
  color: #fff !important;
  background: var(--themeColor) !important;
}
.report-btn .ant-btn.ant-btn-background-ghost:hover {
  color: #fff !important;
  background: var(--themeColor) !important;
}
/* 收藏夹及错题集 查看错题页 */
.box .wrong-record .record-list .top-tab .shu {
  background: var(--themeColor) !important;
}
.box .collect .record-list .top-tab .shu {
  background: var(--themeColor) !important;
}
.box .collect .cont-left-body .material-title li .line {
  background: var(--themeColor) !important;
}
/* 个人中心 - 我的考试 - 查看详情 */
.personal-exam .blue {
  color: var(--themeColor) !important;
}
/* 个人中心-我的题库-tab栏支持变色 */
.personal-center .personal-subject .tab-bar .ant-tabs-tab-active.ant-tabs-tab {
  color: var(--themeColor) !important;
}
.personal-center .personal-subject .tab-bar .ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--themeColor) !important;
}
.personal-center .personal-subject .tab-bar .ant-tabs-ink-bar.ant-tabs-ink-bar-no-animated {
  background: var(--themeColor) !important;
}
/* 我的考试 - 考试记录*/
.paper-start .ant-btn.active,
.paper-start .ant-btn:active {
  color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}
.paper-start .ant-btn.ant-btn-primary:hover {
  border: none !important;
}
.exam-intro .exam-intro-content:hover .class-type-name {
  color: var(--themeColor) !important;
}
/* 导航 */
.layout .navbar {
  background: var(--headerBg) !important;
}
.layout .navbar .left a {
  color: var(--fizeColor);
}
.layout .navbar .ant-menu {
  background: transparent !important;
  border-color: transparent !important;
}
.layout .navbar .ant-menu-dark {
  background: var(--themeColor) !important;
}
.layout .navbar .ant-menu-dark .ant-menu-sub.ant-menu-hidden {
  background: #fff !important;
}
.layout .navbar .ant-menu-horizontal .ant-menu-item {
  background: transparent !important;
  border-color: transparent !important;
}
.layout .navbar .ant-menu-horizontal .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.04) !important;
}
.layout .navbar .ant-menu-horizontal .ant-menu-item .ant-menu-item-selected {
  background: var(--themeColor) !important;
  color: var(--themeColor) !important;
}
.layout .navbar .ant-menu-horizontal .ant-menu-item .ant-menu-item-selected .submenu-title-wrapper {
  color: var(--fizeColor) !important;
}
.layout .navbar .ant-menu-horizontal .ant-menu-item span {
  color: var(--fizeColor) !important;
}
.layout .navbar .ant-menu-submenu:hover {
  color: var(--fizeColor) !important;
  background: rgba(0, 0, 0, 0);
  border-color: transparent !important;
}
.layout .navbar .ant-menu-submenu.ant-menu-submenu-selected {
  border-color: transparent !important;
}
.layout .navbar .ant-menu-submenu.ant-menu-submenu-active {
  color: var(--fizeColor) !important;
  border-color: transparent !important;
}
.layout .navbar .ant-menu-submenu span {
  color: var(--fizeColor) !important;
}
.layout .navbar .school {
  color: var(--fizeColor) !important;
}
.layout .navbar .right {
  color: var(--fizeColor) !important;
}
.layout .navbar .right .list-item {
  color: var(--fizeColor) !important;
}
.layout .navbar .right .no-login a {
  color: var(--fizeColor) !important;
}
.layout .navbar_wind,
.layout .navbar_elegant {
  background: #fff !important;
  color: #666666 !important;
}
.layout .navbar_wind .ant-btn-primary,
.layout .navbar_elegant .ant-btn-primary {
  background: var(--themeColor) !important;
}
.layout .navbar_wind .ant-menu-item-selected span,
.layout .navbar_elegant .ant-menu-item-selected span {
  color: var(--headerBg) !important;
}
.layout .navbar_wind .school,
.layout .navbar_elegant .school {
  color: #2a2a2a !important;
}
.layout .navbar_wind .right,
.layout .navbar_elegant .right {
  color: #666666 !important;
}
.layout .navbar_wind .right .list-item,
.layout .navbar_elegant .right .list-item {
  color: #666666 !important;
}
.layout .navbar_wind .right .list-item:hover,
.layout .navbar_elegant .right .list-item:hover {
  background: transparent!important;
}
.layout .navbar_wind .right .no-login a,
.layout .navbar_elegant .right .no-login a {
  color: #666 !important;
}
.layout .navbar_wind .ant-menu-submenu span,
.layout .navbar_elegant .ant-menu-submenu span {
  color: #666 !important;
}
.layout .navbar_wind .ant-menu-submenu:hover,
.layout .navbar_elegant .ant-menu-submenu:hover {
  background: none!important;
}
.layout .navbar_wind .ant-menu-submenu:hover span,
.layout .navbar_elegant .ant-menu-submenu:hover span {
  color: var(--themeColor) !important;
}
.layout .navbar_wind .ant-menu-item-selected span,
.layout .navbar_elegant .ant-menu-item-selected span {
  color: var(--themeColor) !important;
}
.layout .navbar_wind .right .no-login a,
.layout .navbar_elegant .right .no-login a {
  color: #666 !important;
}
.layout .navbar_wind .right .no-login a:hover,
.layout .navbar_elegant .right .no-login a:hover {
  color: var(--themeColor) !important;
  background: none!important;
}
.ant-menu-submenu .ant-menu-item:hover,
.ant-menu-submenu .ant-menu-item.ant-menu-item-selected,
.ant-menu-submenu .ant-menu-item.ant-menu-item-active {
  background: rgba(var(--themeColor), 0.04) !important;
  color: var(--themeColor) !important;
}
.ant-menu-submenu.ant-menu-submenu-active {
  color: var(--themeColor) !important;
}
.ant-menu-submenu .ant-menu-submenu-title:hover {
  color: var(--themeColor) !important;
}
/* 章节练习 - 查看解析+收起
材料题展开更多
做题记录 - 查看解析 按钮变色
*/
.subject-wrap-doing .content-box .content-left .cont-left-body .show-btn:hover {
  color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}
.subject-wrap-doing .content-box .content-left .question-title .material-title-box .more-btn:hover {
  color: var(--themeColor) !important;
}
.subject-wrap-doing .content-box .content-right .content-right-top .submit {
  background: var(--themeColor) !important;
}
.subject-wrap-doing .content-box .content-right .content-right-top .submit.return {
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
  background: #fff !important;
}
/* 点击任意位置继续 */
.pause-modal .ant-modal-body p:nth-child(2) {
  color: var(--themeColor) !important;
}
/* Cover Ant Design Components Style */
/* Button按钮 */
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  color: var(--themeColor) !important;
  border: 1px solid var(--themeColor) !important;
}
.ant-btn.change-font-background-hover:hover {
  color: #fff !important;
}
.ant-btn.ant-btn-primary {
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
  box-shadow: none;
  text-shadow: none;
}
.ant-btn.ant-btn-primary:hover {
  background-color: rgba(var(--themeRgba), 0.8) !important;
  border-color: var(--themeColor) !important;
  color: #fff !important;
}
.ant-btn.ant-btn-primary:focus {
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
  color: #fff !important;
}
.ant-btn.ant-btn-primary.ant-btn-background-ghost {
  background-color: #fff !important;
  border-color: var(--themeColor) !important;
  color: var(--themeColor) !important;
}
.ant-btn.ant-btn-primary.ant-btn-background-ghost.ghost-background-hover:hover {
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
  color: #fff !important;
}
.ant-btn.ant-btn-primary[disabled],
.ant-btn.ant-btn-primary[disabled]:hover,
.ant-btn.ant-btn-primary[disabled]:active,
.ant-btn.ant-btn-primary[disabled]:focus {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.ant-btn[disabled]:hover {
  background-color: #f5f6f7 !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
/* Tree树形控件 */
.ant-tree .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
  border-color: var(--themeColor) !important;
}
.ant-tree .ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  border-color: var(--themeColor) !important;
  background-color: var(--themeColor) !important;
}
.ant-tree .ant-tree-checkbox.ant-tree-checkbox-checked:after {
  border: 1px solid var(--themeColor) !important;
}
/* Input输入框 */
.ant-input:hover {
  border-color: var(--themeColor) !important;
}
.ant-input:focus {
  border-color: var(--themeColor) !important;
  box-shadow: 0 0 0 2px rgba(var(--themeRgba), 0.2);
}
/* InputNumber数字输入框 */
.ant-input-number:hover {
  border-color: var(--themeColor) !important;
}
.ant-input-number.ant-input-number-focused {
  border-color: var(--themeColor) !important;
  box-shadow: 0 0 0 2px rgba(var(--themeRgba), 0.2);
}
.ant-input-number .ant-input-number-handler-up-inner,
.ant-input-number .ant-input-number-handler-down-inner {
  color: var(--themeColor) !important;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: var(--themeColor) !important;
  box-shadow: 0 0 0 2px rgba(var(--themeRgba), 0.2) !important;
}
.ant-cascader-picker .ant-cascader-input:hover {
  border-color: var(--themeColor) !important;
}
.ant-cascader-picker .ant-cascader-input:focus {
  border-color: var(--themeColor) !important;
  box-shadow: 0 0 0 2px rgba(var(--themeRgba), 0.2) !important;
}
.ant-cascader-menus .ant-cascader-menu-item:hover {
  background: rgba(var(--themeRgba), 0.2) !important;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--themeColor) !important;
  border-right-width: 1px!important;
}
/* Radio单选框 */
.ant-radio:hover .ant-radio-inner {
  border-color: var(--themeColor) !important;
}
.ant-radio.ant-radio-checked .ant-radio-inner {
  border-color: var(--themeColor) !important;
}
.ant-radio.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--themeColor) !important;
}
.ant-radio.ant-radio-checked::after {
  border: 1px solid var(--themeColor) !important;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--themeColor) !important;
  background-color: var(--themeColor) !important;
}
.ant-checkbox.ant-checkbox-checked::after {
  border: 1px solid var(--themeColor) !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--themeColor) !important;
}
/* Select选择器 */
.ant-select.ant-select-open .ant-select-selection {
  border-color: var(--themeColor);
  box-shadow: 0 0 0 2px rgba(var(--themeRgba), 0.2);
}
.ant-select .ant-select-selection:hover,
.ant-select .ant-select-selection:focus {
  border-color: var(--themeColor);
  box-shadow: 0 0 0 2px rgba(var(--themeRgba), 0.2);
}
.ant-select-dropdown .ant-select-dropdown-menu-item-selected {
  font-weight: normal;
}
.ant-select-dropdown .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: rgba(var(--themeRgba), 0.08);
  color: var(--themeColor);
}
.ant-select-dropdown .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  background: rgba(var(--themeRgba), 0.08);
  color: var(--themeColor);
}
/* DatePicker日期选择框 */
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--themeColor);
  box-shadow: 0 0 0 2px rgba(var(--themeRgba), 0.2);
}
.ant-calendar-picker .ant-calendar-body .ant-calendar-date:hover {
  background: rgba(var(--themeRgba), 0.3);
}
.ant-calendar-picker .ant-calendar-today .ant-calendar-date {
  color: var(--themeColor);
  border-color: var(--themeColor);
}
.ant-calendar-picker .ant-calendar-selected-day .ant-calendar-date {
  background: rgba(var(--themeRgba), 0.3);
}
.ant-calendar-picker .ant-calendar-footer .ant-calendar-today-btn {
  color: var(--themeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-year-select:hover,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-year-panel-decade-select:hover,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-month-select:hover,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-month-panel-year-select:hover {
  color: var(--themeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-month-panel-selected-cell:not(.ant-calendar-month-panel-cell-disabled) .ant-calendar-month-panel-month {
  color: var(--fizeColor);
  background: var(--themeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-decade-panel-decade:hover,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-year-panel-year:hover,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-header .ant-calendar-month-panel-cell:not(.ant-calendar-month-panel-cell-disabled) .ant-calendar-month-panel-month:hover {
  color: var(--themeColor);
  background: rgba(var(--themeRgba), 0.2);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-tbody .ant-calendar-cell:not(.ant-calendar-next-month-btn-day):not(.ant-calendar-last-month-cell):not(.ant-calendar-selected-start-date):not(.ant-calendar-selected-end-date):not(.ant-calendar-disabled-cell) .ant-calendar-date:hover {
  background: rgba(var(--themeRgba), 0.2);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-tbody .ant-calendar-cell.ant-calendar-in-range-cell::before {
  background: rgba(var(--themeRgba), 0.2);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-tbody .ant-calendar-cell.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-tbody .ant-calendar-cell.ant-calendar-selected-end-date .ant-calendar-date {
  background: var(--themeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-tbody .ant-calendar-cell.ant-calendar-today .ant-calendar-date {
  color: var(--themeColor);
  border-color: var(--themeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-tbody .ant-calendar-cell.ant-calendar-selected-day .ant-calendar-date {
  color: var(--fizeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-footer .ant-calendar-footer-btn .ant-calendar-ok-btn {
  border-color: var(--themeColor);
  background: var(--themeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-footer .ant-calendar-footer-btn .ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-footer .ant-calendar-footer-btn .ant-calendar-time-picker-btn {
  color: var(--themeColor);
}
.ant-calendar-picker-container .ant-calendar-range .ant-calendar-footer .ant-calendar-footer-btn .ant-calendar-time-picker-btn.ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-form .has-error .ant-input:hover {
  border-color: #f5222d !important;
}
.ant-form .has-error .ant-input:focus {
  border-color: #f5222d !important;
}
.personal-setting .module-title::before {
  background-color: var(--themeColor) !important;
}
.personal-setting .module-title span {
  color: var(--themeColor) !important;
}
.personal-setting .instead-apply .instead-tabs-nav .active {
  background: var(--themeColor) !important;
  color: #fff;
  border-radius: 15px;
}
.personal-setting .instead-apply .instead-list .instead-item .completed {
  border-color: var(--themeColor) !important;
  color: var(--themeColor) !important;
}
.personal-setting .instead-apply .instead-list .instead-item .unfinished {
  background: var(--themeColor) !important;
  color: #fff;
}
.personal-setting .account-info .third-bind .bind-status .binding {
  color: var(--themeColor) !important;
}
.personal-setting .account-info .modified-account .code-button {
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
}
/*
个人中心 - 课程中心
*/
.course-center-com .late-learn i,
.course-center-com .late-learn span {
  color: var(--themeColor);
}
.center-course-com .course-tab span.active {
  background-color: var(--themeColor);
}
